"use strict";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");

var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");

var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");

var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _isNan = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/number/is-nan"));

var _url = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/url"));

var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));

var Misc = _interopRequireWildcard(require("@/helpers/Misc"));

var _Captcha = _interopRequireDefault(require("@/components/Captcha"));

var _ServerSelector = _interopRequireDefault(require("./ServerSelector"));

function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

var _default = {
  components: {
    ServerSelector: _ServerSelector.default,
    Captcha: _Captcha.default
  },
  props: ['network'],
  data: function data() {
    return {
      server_type: 'network',
      znc_username: '',
      znc_network: '',
      znc_password: '',
      show_advanced: false,
      switch_tabs_on_connect: false,
      network_list: []
    };
  },
  computed: {
    settingShowRaw: {
      get: function get() {
        return this.network.setting('show_raw');
      },
      set: function set(val) {
        return this.network.setting('show_raw', val);
      }
    },
    directWs: {
      get: function get() {
        if (!this.network.connection.direct) {
          return '';
        }

        var connection = this.network.connection;
        var addr = '';
        addr += connection.tls ? 'wss://' : 'ws://';
        addr += connection.server;
        var port = parseInt(connection.port, 10);

        if ((0, _isNan.default)(port)) {
          port = connection.tls ? 443 : 80;
        } // Only include the port if needed


        if (connection.tls && port !== 443 || !connection.tls && port !== 80) {
          addr += ':' + connection.port;
        }

        addr += connection.path;
        return addr;
      },
      set: function set(newVal) {
        var url = null;

        try {
          url = new _url.default(newVal);
        } catch (e) {
          return;
        }

        var connection = this.network.connection;
        connection.tls = url.protocol.toLowerCase() === 'wss:';
        connection.server = url.hostname;
        var port = parseInt(url.port, 10);

        if ((0, _isNan.default)(port)) {
          port = url.protocol.toLowerCase() === 'wss:' ? 443 : 80;
        }

        connection.port = port;
        var u = url.href.replace(url.protocol + '//', '');
        connection.path = u.substr(u.indexOf('/'));
      }
    }
  },
  watch: {
    znc_username: function znc_username() {
      this.setZncPass();
    },
    znc_network: function znc_network() {
      this.setZncPass();
    },
    znc_password: function znc_password() {
      this.setZncPass();
    },
    'network.state': function networkState() {
      if (!this.switch_tabs_on_connect) {
        return;
      }

      if (this.network.state === 'connected') {
        this.switch_tabs_on_connect = false;
        this.$state.$emit('server.tab.show', 'messages');
      } else if (this.network.state_error) {
        this.switch_tabs_on_connect = false;
      }
    }
  },
  created: function created() {
    var isZnc = !!(this.network.connection.password || '').match(/^(.*)\/(.*):(.*)$/);
    this.server_type = isZnc ? 'znc' : 'network';

    if (isZnc) {
      var match = (this.network.connection.password || '').match(/^(.*)\/(.*):(.*)$/);
      this.znc_username = match[1] || '';
      this.znc_network = match[2] || '';
      this.znc_password = match[3] || '';
    }

    this.network_list = this.$state.setting('presetNetworks') || [];
  },
  methods: {
    readableStateError: function readableStateError(err) {
      return Misc.networkErrorMessage(err);
    },
    connect: function connect() {
      this.switch_tabs_on_connect = true;
      this.network.ircClient.connect();
    },
    reconnect: function reconnect() {
      this.network.ircClient.connect();
    },
    removeNetwork: function removeNetwork() {
      /* eslint-disable no-restricted-globals, no-alert */
      var confirmed = confirm('Really remove this network? This cannot be undone!');

      if (!confirmed) {
        return;
      }

      this.$state.removeNetwork(this.network.id);
      this.$state.$emit('active.component');
    },
    setZncPass: function setZncPass() {
      var _context, _context2;

      var newPass = (0, _concat.default)(_context = (0, _concat.default)(_context2 = "".concat(this.znc_username, "/")).call(_context2, this.znc_network, ":")).call(_context, this.znc_password);
      this.network.connection.password = newPass;
    },
    toggleTls: function toggleTls() {
      var connection = this.network.connection;
      connection.tls = !connection.tls; // Switching the port only if were currently using the most common TLS/plain text ports

      if (connection.tls && connection.port === 6667) {
        connection.port = 6697;
      } else if (!connection.tls && connection.port === 6697) {
        connection.port = 6667;
      }
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["NetworkSettings"]
window._kiwi_exports.components.NetworkSettings = exports.default ? exports.default : exports;
