import { render, staticRenderFns } from "./MessageInfo.vue?vue&type=template&id=5d9c012e&"
import script from "./MessageInfo.vue?vue&type=script&lang=js&"
export * from "./MessageInfo.vue?vue&type=script&lang=js&"
import style0 from "./MessageInfo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports