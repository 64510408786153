var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kiwi-messageinfo",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.message.mentioned_urls.length > 0)?_c('div',{staticClass:"kiwi-messageinfo-urls"},_vm._l((_vm.message.mentioned_urls),function(url){return _c('div',{key:url,staticClass:"kiwi-messageinfo-url"},[_c('a',{staticClass:"u-button u-button-secondary",on:{"click":function($event){return _vm.urlPreview(url)}}},[_vm._v("Preview")]),_c('a',{staticClass:"u-link",attrs:{"href":url,"target":"_blank"}},[_vm._v(" "+_vm._s(url)+" ")])])}),0):_vm._e(),_c('div',{staticClass:"kiwi-messageinfo-actions",class:{'kiwi-messageinfo-actions--open': _vm.requestingInput}},[(!_vm.requestingInput)?_vm._l((_vm.pluginUiSections),function(plugin){return _c('a',{directives:[{name:"rawElement",rawName:"v-rawElement",value:({
                    el: plugin.el,
                    props: {
                        kiwi: {
                            buffer: _vm.buffer,
                            message: _vm.message,
                        }
                    }
                }),expression:"{\n                    el: plugin.el,\n                    props: {\n                        kiwi: {\n                            buffer: buffer,\n                            message: message,\n                        }\n                    }\n                }"}],key:plugin.id,staticClass:"u-link kiwi-messageinfo-reply kiwi-messageinfo-plugin"})}):_vm._e(),(!_vm.requestingInput)?_c('a',{staticClass:"u-link kiwi-messageinfo-reply",on:{"click":_vm.openQuery}},[_vm._v(" Reply in private ")]):_vm._e(),(_vm.areWeAnOp() && !_vm.isSelf())?_c('div',{staticClass:"kiwi-messageinfo-opbuttons"},[_c('input-prompt',{attrs:{"label":"Kick reason:"},on:{"submit":_vm.onKick,"cancel":function($event){_vm.requestingInput = false}}},[(!_vm.requestingInput)?_c('a',{staticClass:"u-link kiwi-messageinfo-kick-user",on:{"click":function($event){_vm.requestingInput = true}}},[_vm._v(" Kick "+_vm._s(_vm.message.nick)+" ")]):_vm._e()]),_c('input-prompt',{attrs:{"label":"Ban reason:"},on:{"submit":_vm.onBan,"cancel":function($event){_vm.requestingInput = false}}},[(!_vm.requestingInput)?_c('a',{staticClass:"u-link kiwi-messageinfo-ban-user",on:{"click":function($event){_vm.requestingInput = true}}},[_vm._v(" Ban "+_vm._s(_vm.message.nick)+" ")]):_vm._e()])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }